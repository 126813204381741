* { 
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: #000;
}

#canvas {
    /* border: 2px solid #fff; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 276px;

    /* width: 568px;
    height: 800px; */
}